import CryptoJS from "crypto-js";
import uuid from "uuid-random";

import { RequestBuilder, intercept } from "@services/apis/request.js";
import { endpoints } from "@services/apis/endpoint";
import { setValueInLocalStorage } from "@services/localStorageHandlers";
import { keys } from "@services/constants";
import tenantConfig from "@services/tenant.config.json";

const { REACT_APP_HMAC_SALT: salt, REACT_APP_env: appEnv, REACT_APP_tenant: tenant } = process.env;
export const { apiBaseUrl, slug, hmacUsername } = tenantConfig[tenant];

const HMACHeaders = (method, endpoint) => {
	const date = new Date().toGMTString("EEE, dd MMM YYYY HH:mm:ss zzz");
	const signature = `x-date: ${date}\n${method} ${endpoint} HTTP/1.1\nx_gmp_tenant: ${slug}`;
	const hash = CryptoJS.HmacSHA256(signature, salt);
	const h = CryptoJS.enc.Base64.stringify(hash).toString();

	return {
		"content-type": "application/json",
		"x-date": date,
		X_DEVICE_ID: "Web",
		X_GMP_TENANT: slug,
		Authorization: `hmac username="${hmacUsername}", algorithm="hmac-sha256", headers="x-date request-line x_gmp_tenant", signature="${h}"`,
		X_GMP_CORRELATION_ID: uuid(),
	};
};

const HMACRequest = new RequestBuilder(
	apiBaseUrl[appEnv],
	{ headers: HMACHeaders },
	intercept
);

export const fetchMasterConfig = async () => {
	// const entityId = 'https://permit.getmyparking.com'
	// const entityId = 'https://pms-qaforqa-nmi-oneparking.getmyparking.com'
	// const entityId = "https://pms-devforqa-nmi-oneparking.getmyparking.com";
	const entityId = window.location.origin;

	try {
		const [error, response] = await HMACRequest.get(endpoints.MASTER_CONFIG, {
			attributeType: "PAYMENT_GATEWAY_ATTRIBUTE",
			entityType: "URL",
			entityId: entityId,
		});
		if (error || !response?.length) {
			throw new Error(error);
		}
		const companyId = response[0]?.companyId;
		const web_config = response[0].attributeData;
		web_config['companyId'] = companyId

		//store the config in localstorage
		setValueInLocalStorage(keys.WEB_CONFIG, web_config);
		return { ...web_config, companyId: companyId };
	} catch (e) {
		throw new Error(e);
	}
};
