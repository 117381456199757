import uuid from "uuid-random";
import CryptoJS from "crypto-js";

import { queryString } from "@services/helper";
import tenantConfig from "@services/tenant.config.json";
import { keys } from "@services/constants";
import { getValueFromLocalStorage } from "@services/localStorageHandlers";

export const salt = () =>{
	const { REACT_APP_HMAC_SALT: salt } = process.env
	return salt
}

export const hmacUsername = () =>{
	return getValueFromLocalStorage(keys.WEB_CONFIG)?.hmacUsername
}

export const slug = () =>{
	return getValueFromLocalStorage(keys.WEB_CONFIG)?.slug
} 

export const authToken = () =>{
	return getValueFromLocalStorage(keys.PRODUCT_CONFIG)?.uuid
}

export const apiBaseUrlConfig = () => {
	const { REACT_APP_env: env} = process.env
	const config = getValueFromLocalStorage(keys.WEB_CONFIG)
	const baseUrl = config?.apiBaseUrl[env]
	return baseUrl
}

export const JWTHeaders = (method, endpoint, contentType) => {
	return {
		'content-type': contentType ? contentType : 'application/json',
		X_DEVICE_ID: 'Web',
		X_DEVICE_TYPE: 'Web',
		X_GMP_TENANT: slug(),
		// X_LANGUAGE_CODE: getBrowserLanguage(navigator.language),
		// X_AUTH_REQUEST_TYPE: 'jwt_consumer',
		// Authorization: authorizationToken ? `Bearer ${authorizationToken}` : null,
		Authorization: `Key ${authToken()}`,
		X_GMP_CORRELATION_ID: uuid(),
	}
}

export const HMACHeaders = (method, endpoint) => {
	const date = new Date().toGMTString("EEE, dd MMM YYYY HH:mm:ss zzz");
	const signature = `x-date: ${date}\n${method} ${endpoint} HTTP/1.1\nx_gmp_tenant: ${slug()}`;
	const hamcSalt = salt()
	const hash = CryptoJS.HmacSHA256(signature, hamcSalt);
	const h = CryptoJS.enc.Base64.stringify(hash).toString();

	return {
		"content-type": "application/json",
		"x-date": date,
		X_DEVICE_ID: "Web",
		X_DEVICE_TYPE: "Web",
		X_GMP_TENANT: slug(),
		Authorization: `hmac username="${hmacUsername()}", algorithm="hmac-sha256", headers="x-date request-line x_gmp_tenant", signature="${h}"`,
		X_GMP_CORRELATION_ID: uuid(),
	};
};

export const intercept = async (request) => {
	const { status } = request;
	switch (true) {
		case status === 419:
			throw new Error((await request.json()).message);
		case status === 400:
			throw new Error((await request.json()).message);
		case status === 401:
			throw new Error((await request.json()).message);
		case status === 404:
			throw new Error((await request.json()).message || "Not found");
		case status === 500:
			throw new Error("Something went wrong");
		case status === 204:
			return { status: "OK" };
		default:
			return request.json();
	}
};

// RequestBuilder class to create instance of request and exposing get, post, put, delete and a generic make method
// for method signatures refer below
export class RequestBuilder {
	constructor(BaseUrl, options, interceptor) {
		this.API_URL = BaseUrl ?? "";
		this.options = options ?? {};
		this.interceptor = interceptor ?? ((request) => request.json());
	}

	setApiBaseUrl() {
		if(this.API_URL) return
		const baseUrlConfig = apiBaseUrlConfig()
		this.API_URL = baseUrlConfig
	}


	// function makes an api call, any logs for the apis logs can be attached here
	makeRequest = async (endpoint, options) => {
		const [error, response] = await fetch(endpoint, options)
			.then(async (response) => [null, await this.interceptor(response)])
			.catch((error) => [error, null]);
		return [error, response];
	};

	get = async (url, params, options = {}) => {
		this.setApiBaseUrl()
		const endpoint = `${this.API_URL}${url}${
			params ? `?${queryString(params)}` : ""
		}`,
		method = "GET";
		return this.makeRequest(endpoint, {
			method,
			...options,
			headers: this.options.headers(method, url),
		});
	};

	post = async (url, data, params, options = {}) => {
		this.setApiBaseUrl()
		const endpoint = `${this.API_URL}${url}${
				params ? `?${queryString(params)}` : ""
			}`,
			method = "POST";

		return this.makeRequest(endpoint, {
			method,
			headers: this.options.headers(method, url),
			...(data && { body: JSON.stringify(data), ...options }),
		});
	};
}

// export instance of RequestBuilder with default options
export const HMACRequest = new RequestBuilder(
	null,
	{ headers: HMACHeaders },
	intercept
);

export const AuthRequest = new RequestBuilder(
	null,
	{ headers: JWTHeaders },
	intercept
);