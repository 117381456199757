const authentication = "/authentication/v1";

export const endpoints = {
  MASTER_CONFIG: authentication + '/company/companyAttribute',
  GET_PAYMENT_DETAILS: authentication + "/payments/gateway/getPaymentGatewayDetails",
  GET_REDIRECT_URL: authentication + "/consumer/payment/redirectUrl",
  ADD_PAYMENT: authentication + "/consumer/payment/v2/addConsumerPaymentMethodV2",
  CUSTOMER_TOKEN: authentication + "/payment/transaction/customerToken",
  CLIENT_SECRET: authentication  + '/consumer/payment/v2/getPaymentSecretKey',
};
