export const getValueFromLocalStorage = (key) => {
	const item = localStorage.getItem(`${key}`);
	return item && item !== "undefined" && item !== "null"
		? JSON.parse(localStorage.getItem(`${key}`))
		: null;
};

export const setValueInLocalStorage = (key, value) => {
	localStorage.setItem(`${key}`, JSON.stringify(value));
	return;
};

export const removeValueFromLocalStorge = (key) => {
	localStorage.removeItem(key)
}

export const removeAllValueFromLocalStorage = () => {
	localStorage.clear()
	return
}

export const getValueFromSessionStorage = (key) => {
	const item = sessionStorage.getItem(`${key}`);
	return item && item !== "undefined" && item !== "null"
		? JSON.parse(sessionStorage.getItem(`${key}`))
		: null;
};

export const setValueInSessionStorage = (key, value) => {
	sessionStorage.setItem(`${key}`, JSON.stringify(value));
	return;
};

export const removeAllValueFromSessionStorage = (key) => {
	sessionStorage.clear()
	return
}