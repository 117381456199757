import forge from "node-forge";

import { PaymentGateway, keys, ProductType } from "@services/constants";
import { setValueInLocalStorage } from "@services/localStorageHandlers";

export const queryString = (params) =>
	Object.keys(params)
		.map((key) => {
			const value = Array.isArray(params[key])
				? params[key].join(",")
				: params[key];
			return key + "=" + value;
		})
		.join("&");

export const decryptCustomerToken = (token) => {
	const { REACT_APP_RSA_PRIVATE_KEY: privateKey } = process.env;
	const encryptionScheme = "RSA-OAEP";
	const decryptedData = forge.pki
		.privateKeyFromPem(privateKey)
		.decrypt(forge.util.decode64(token), encryptionScheme);
	setValueInLocalStorage(keys.PAYMENT_CONFIG, decryptedData);
	return decryptedData;
};

export const isHostedPages = (paymentGateway) => {
	const hostedPagesGateway = [
		...PaymentGateway.EVO,
		...PaymentGateway.INGENICO,
		...PaymentGateway.TELR,
		...PaymentGateway.TPAY,
		...PaymentGateway.WINDCAVE,
	];
	return hostedPagesGateway.includes(paymentGateway.toUpperCase());
};

export const isHostedFields = (paymentGateway) => {
	const hostedFieldGateways = [
		...PaymentGateway.GLOBAL,
		...PaymentGateway.NMI,
		...PaymentGateway.CHECKOUT,
		...PaymentGateway.STRIPE,
		...PaymentGateway.BRAINTREE,
		...PaymentGateway.PAGAR,
		...PaymentGateway.DATACAP,
	];
	return hostedFieldGateways.includes(paymentGateway);
};

export const buttonCTAText = (productType) =>{
	if(productType === ProductType.PERMIT){
		return 'button.addCardPermit'
	}
	if(productType === ProductType.MOBILE){
		return 'button.addCardMobile'
	}
	if(productType === ProductType.POSTPAY){
		return 'button.addCardPostPay'
	}
	if(productType === ProductType.PREPAY){
		return 'button.addCardPrepay'
	}
	if(productType === ProductType.PARTNER_PORTAL){
		return 'button.addCardPartnerPortal'
	}else{
		return 'button.addCardOther'
	}
}