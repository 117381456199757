export const keys = {
	PAYMENT_CONFIG: "PAYMENT_CONFIG",
	PRODUCT_CONFIG: "PRODUCT_CONFIG",
	WEB_CONFIG: "WEB_CONFIG",
	VERIFYING_PAYMENT: "VERIFYING_PAYMENT"
};

export const PaymentGateway = Object.freeze({
	GLOBAL: ["GLOBAL", "GLOBALPAYMENTS"] ,
	NMI: ["NMI"],
	CHECKOUT: ["CHECKOUT"],
	STRIPE: ["STRIPE","STRIPE_V2"],
	DATACAP: ["DATACAP_CLIENT"],

	WINDCAVE: ["WINDCAVE"],
	TELR: ["TELR"],
	EVO: ["EVO"],
	INGENICO: ["INGENICO"],
	TPAY: ["TPAY"],

	BRAINTREE: ["BRAINTREE"],
	PAGAR: ["PAGAR"],
});

export const HostedPaymentStatus = Object.freeze({
	SUCCESS: "success",
	CANCEL: "cancel",
	DECLINE: "decline",
});

export const ProductType = Object.freeze({
	GATED: "GATED",
	UNGATED: "UNGATED",
	PERMIT: "PERMIT",
	POSTPAY:"POSTPAY",
	PREPAY:"PREPAY",
	PARTNER_PORTAL: "PARTNER_PORTAL",
	MOBILE: "MOBILE",
	OTHER: "OTHER",
});
