import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack'

import "@src/index.css";
import tenantConfig from "@services/tenant.config.json";
import { fetchMasterConfig } from "@services/fetchMasterConfig";
import { keys } from "@services/constants";
import { getValueFromLocalStorage, removeAllValueFromLocalStorage } from "@services/localStorageHandlers";
import reportWebVitals from "@src/reportWebVitals";

const App = lazy( () => import ('@src/App'))

const envTenant = process.env.REACT_APP_tenant
const defaultConfig = tenantConfig[envTenant]

const rootTag = document.querySelector(":root");
	rootTag.style.setProperty("--primary", defaultConfig?.theme.primary ?? '#78b51a');
	rootTag.style.setProperty("--secondary", defaultConfig?.theme.secondary ?? "#171b21");

document.title = defaultConfig?.title

const root = ReactDOM.createRoot(document.getElementById("root"));

window.addEventListener("pageshow", (event) => {
	const isPaymentVerificationInPrgoress = getValueFromLocalStorage(keys.VERIFYING_PAYMENT)
	if(event.persisted && !isPaymentVerificationInPrgoress){
		removeAllValueFromLocalStorage()
		if (caches) {
			caches.keys().then(function (names) {
				for (let name of names) caches.delete(name)
			})
		}
     	window.location.replace('/*')
	}
})

window.addEventListener("pagehide", (event) => {
	const isPaymentVerificationInPrgoress = getValueFromLocalStorage(keys.VERIFYING_PAYMENT)
	if(!isPaymentVerificationInPrgoress){
		removeAllValueFromLocalStorage()
		if (caches) {
			caches.keys().then(function (names) {
				for (let name of names) caches.delete(name)
			})
		}
	}
})

window.addEventListener('beforeunload', (e)=>{
	const isPaymentVerificationInPrgoress = getValueFromLocalStorage(keys.VERIFYING_PAYMENT)
	if(!isPaymentVerificationInPrgoress){
		removeAllValueFromLocalStorage()
		if (caches) {
			caches.keys().then(function (names) {
				for (let name of names) caches.delete(name)
			})
		}
	}
})

fetchMasterConfig()
	.then(res=>{
		root.render(
			<BrowserRouter>
				<SnackbarProvider>
					<App />
				</SnackbarProvider>
			</BrowserRouter>
		);
	})
	.catch(e => {
		const failureUrl = getValueFromLocalStorage(keys.PRODUCT_CONFIG)?.failureUrl
		if(failureUrl){
			window.location.replace(failureUrl)
		}
		console.log(e)
	})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
